<template>
  <div>
    <HeroSection />
    <Mission />
    <!-- <Banner />
    <FeaturesSection />
    <TeamSection />

    <TestimonialSection
      subject="Andrea Cappelletti"
      patology="Reflusso Gastroesofageo"
      link="reflusso"
      :image="localImage1"
      testimonial="Grande professionalità e attenzione per il paziente."
    /> -->
    <!--
    <TestimonialSection
      subject="Zlatan Ibrahimović"
      patology="Ernia jatale permagna"
      :image="localImage"
      testimonial="Wow! Operano davvero bene qui al centro..."
    /> -->
    <!-- <CtaSection />
    -->
      <FooterSection /> 

    <!-- <Banner /> -->
    <!-- <Contacts /> -->
  </div>
</template>

<script>
import HeroSection from "../components/HeroSection.vue";
// import FeaturesSection from "../components/FeaturesSection.vue";
// import TeamSection from "../components/TeamSection.vue";
// import TestimonialSection from "../components/TestimonialSection.vue";
// import CtaSection from "../components/CtaSection.vue";
// import Banner from "../components/Banner.vue";
import FooterSection from "../components/FooterSection.vue";
import Mission from "../components/Mission.vue";
// import testimonialImage1 from "@/assets/testimonial/andrea.jpg";

export default {
  name: "Home",
  components: {
    HeroSection,
    Mission,
    // FeaturesSection,
    // TeamSection,
    // TestimonialSection,
    // CtaSection,
    FooterSection,

    // Banner,
  },
  data() {
    return {
      // localImage1: testimonialImage1,
    };
  },
};
</script>
